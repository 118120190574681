<template>
	<div class="land-transaction">
		<div class="top-img">
			<div class="top-name" v-if="!subscribeId">土地交易信息库</div>
			<div class="search-input">
				<el-input v-model.trim="conPO.search" placeholder="请输入项目名称等关键词" maxlength="100"
					@keyup.enter.native="handleQuery"></el-input>
				<span class="search-btn" @click="handleQuery">查询</span>
			</div>
		</div>
		<div class="title-card">
			<div class="title-box">
				<div class="title">土地交易信息库</div>
				<div class="result">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>

			</div>
			<div class="condition-box">
				<div class="condition-item">
					<div class="title">省份</div>
					<div class="item-box">
						<div class="all-btn" :class="isProAll ? 'all-btn-select' : ''" @click="proAll">全部</div>
						<div class="name-box" :class="provincesStatus ? 'name-open' : ''">
							<div class="name" :class="proSelect.indexOf(item) !== -1 ? 'name-select' : ''"
								v-for="item in regionList" :key="item.code" @click="selectPro(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldProvinces">
						<span>{{ provincesStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="provincesStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item" v-show="cityData.length != 0">
					<div class="title">地级市</div>
					<div class="item-box">
						<div class="name-box" :class="cityStatus ? 'name-open' : ''">
							<div class="name" :class="citySelect.indexOf(item) !== -1 ? 'name-select' : ''"
								v-for="item in cityData" :key="item.code" @click="selectCity(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldCity">
						<span>{{ cityStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="cityStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item">
					<div class="title" style="margin-top: 10px">时间</div>
					<div class="year-box">
						<div class="all-btn" :class="isYearAll ? 'all-btn-select' : ''" @click="yearAll">不限</div>
						<div class="name-box">
							<div class="name" :class="yearSelect.indexOf(item.code) !== -1 ? 'name-select' : ''"
								v-for="item in yearData" :key="item.code" @click="selectYear(item)">
								{{ item.name }}
							</div>
						</div>
						<div class="custom-box">
							<div class="custom">自定义</div>
							<div>
								<el-date-picker v-model="startYear" type="month" :picker-options="startTime"
									:default-value="maxDate" placeholder="开始时间" value-format="yyyy-MM"
									@change="changeYear"></el-date-picker>
							</div>
							<div class="to">至</div>
							<div>
								<el-date-picker v-model="endYear" type="month" :picker-options="endTime"
									:default-value="maxDate" placeholder="结束时间" value-format="yyyy-MM"
									@change="changeYear"></el-date-picker>
							</div>
						</div>
					</div>
				</div>
				<div class="condition-item">
					<div class="title" style="margin-top: 10px">土地面积</div>
					<div class="year-box">
						<div class="all-btn" :class="isInvestmentAll ? 'all-btn-select' : ''" @click="investmentAll">不限
						</div>
						<div class="name-box">
							<div class="name" :class="conPO.investmentIndex === index ? 'name-select' : ''"
								v-for="(item, index) in investmentList" :key="item.name"
								@click="selectInvestment(item, index)">
								{{ item.name }}
							</div>
						</div>
						<div class="custom-box">
							<div class="custom">自定义</div>
							<div>
								<el-input v-model="conPO.customlandAreaStart" clearable type="number"
									oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 120px;" placeholder="最小面积"
									@change="changeCustomInvestment" />
								㎡
							</div>
							<div class="to">至</div>
							<div>
								<el-input v-model="conPO.customlandAreaEnd" clearable type="number"
									oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 120px;" placeholder="最大面积"
									@change="changeCustomInvestment" />
								㎡
							</div>
						</div>
					</div>
				</div>

				<div class="condition-item" style="margin-top: -10px">
					<div class="title" style="margin-top: 10px">土地用途</div>
					<div class="year-box">
						<div class="name-box" v-for="item in landUseList" :key="item.dictValue">
							<div class="name" :class="selectedStageData.indexOf(item) !== -1 ? 'name-select' : ''"
								@click="selectState(item, 1)">
								{{ item.title }}
							</div>
						</div>
					</div>
				</div>
				<div class="condition-item" style="margin-top: -10px" v-if="selectedStageData.length > 0">
					<div class="title" style="margin-top: 10px">{{ selectedStageItem.title }}</div>
					<div class="year-box">
						<div class="name-box" v-for="item in childCodeList" :key="item.dictValue">
							<div class="name" :class="selectedStage2Data.indexOf(item.text) !== -1 ? 'name-select' : ''"
								@click="selectState(item, 2)">
								{{ item.text }}
							</div>
						</div>

					</div>
				</div>
				<template v-if="openMore">
					<div class="condition-item" style="margin-top: -10px">
						<div class="title" style="margin-top: 10px">开工日期</div>
						<div class="year-box">
							<div class="name-box" v-for="item in timeList" :key="item.dictValue">
								<div class="name" :class="selecteTimeData.indexOf(item) !== -1 ? 'name-select' : ''"
									@click="selectType(item, 1)">
									{{ item.text }}
								</div>
							</div>
						</div>
					</div>
					<div class="condition-item" style="margin-top: -10px">
						<div class="title" style="margin-top: 10px">成交总价</div>
						<div class="year-box">
							<div class="name-box" v-for="item in priceList" :key="item.dictValue">
								<div class="name" :class="selectedPriceData.indexOf(item) !== -1 ? 'name-select' : ''"
									@click="selectType(item, 2)">
									{{ item.text }}
								</div>
							</div>
						</div>
					</div>
					<div class="condition-item" style="margin-top: -10px">
						<div class="title" style="margin-top: 10px">供地方式</div>
						<div class="year-box">
							<div class="name-box" v-for="item in typeList" :key="item.dictValue">
								<div class="name" :class="selectedTypeData.indexOf(item) !== -1 ? 'name-select' : ''"
									@click="selectType(item, 3)">
									{{ item.text }}
								</div>
							</div>
						</div>
					</div>
				</template>
				<div class="condition-item" v-show="(selectedProData.length != 0 || selectedCityData.length != 0 || selectedYearData.length != 0 || selectedTypeData.length != 0 || selectedStage2Data.length != 0 || selecteTimeData.length != 0 || selectedPriceData.length != 0 || (conPO.investmentIndex || conPO.investmentIndex === 0)) &&
					!(
						selectedStageData.length == 1 &&
						selectedStageData.findIndex(item => {
							return item.dictValue == '61001011';
						}) == 0
					)
					">
					<div class="title">已选条件</div>
					<div class="selected-box">
						<div class="name" v-for="(item, i) in selectedProData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 1)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedCityData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 2)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedYearData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 3)"></div>
						</div>
						<div class="name" v-if="conPO.investmentIndex || conPO.investmentIndex === 0">
							<div>{{ investmentList[conPO.investmentIndex].name }}</div>
							<div class="clear-icon" @click="delSelectData('', '', 8)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedStage2Data" :key="item.id">
							<div>{{ item }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 5)"></div>
						</div>

						<div class="name" v-for="(item, i) in selecteTimeData" :key="item.id">
							<div>{{ item.text }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 6)"></div>
						</div>

						<div class="name" v-for="(item, i) in selectedPriceData" :key="item.id">
							<div>{{ item.text }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 7)"></div>
						</div>

						<div class="name" v-for="(item, i) in selectedTypeData" :key="item.id">
							<div>{{ item.text }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 4)"></div>
						</div>
					</div>
					<div class="empty-btn" @click="emptySelect">清空筛选条件</div>
				</div>
				<div class="flex-center">

					<div class="open" @click="openMore = !openMore">
						<span>{{ openMore ? "收起选项" : "更多选项" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="openMore ? 'iconfont-open' : ''"></span>
					</div>
				</div>


			</div>
		</div>
		<div class="list-box">
			<div class="empty-box" v-show="total == 0">
				<empty :name="'暂无数据'"></empty>
			</div>
			<div class="list-item" v-for="(item, index) in pageBidding" :key="index" @click="toDetail(item)">
				<div class="name-top flex items-center justify-between">
					<el-tooltip effect="light" :content="item.projectName" placement="bottom-start">
						<div v-html="item.projectName" class="text-ellipsis"></div>
					</el-tooltip>
					<collect v-model="item.isCollection" :ab="false" :itemId="item.id" type="土地交易"></collect>
				</div>
				<div class="bottom-box">
					<div class="tag-item flex-1">行政区划：{{ item.region || '-' }}</div>
					<div class="tag-item flex-1">土地使用权人：{{ item.landUseRight || '-' }}</div>
					<div class="tag-item flex-1">合同签订日期：{{ item.signingDate || '-' }}</div>
				</div>
				<div class="bottom-box">
					<div class="tag-item flex-1">土地用途：{{ item.landUse || '-' }}</div>
					<div class="tag-item flex-1">出让面积：{{ (item.landArea + '㎡' || '-') }}</div>
					<div class="tag-item flex-1">计划开工日期：{{ item.commencementDate || '-' }}</div>
				</div>
				<div class="bottom-content-box" v-show="item.contentsHighLight">
					<span class="contentTitle">内容：</span>
					<span v-html="item.contentsHighLight"></span>
				</div>
			</div>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0" :key="paginationKey">
			</pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showCommonText="false" :text="text"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import Empty from "@/components/Empty";
import MemberModal from "@/components/MemberModal";
import ToPayOrMember from "@/components/ToPayOrMember";
import { doEncrypt, doDecryptStr } from "@/utils/smCrypto.js";
import collect from "@/components/collect";
import { mapGetters } from "vuex";
export default {
	dicts: ["6068"],
	components: {
		Pagination,
		LoginModal,
		Empty,
		MemberModal,
		ToPayOrMember,
		collect,
	},
	props:{
		subscribeId:{
			type:String,
			default:''
		}
	},
	data() {
		return {
			cityData: [], // 市级数据
			provincesStatus: false, // 省份展开收起状态
			cityStatus: false, // 地级市展开收起状态
			proSelect: [], // 选中的省份
			isProAll: true, // 省份是否全选
			citySelect: [], // 选中的地级市
			isYearAll: true, // 年份是否全选
			yearSelect: [], // 选中的年份
			projectState: false,
			yearData: [
				{
					name: "今天",
					code: "1",
				},
				{
					name: "近3天",
					code: "3",
				},
				{
					name: "近7天",
					code: "7",
				},
				{
					name: "近30天",
					code: "30",
				},
				{
					name: "近60天",
					code: "60",
				},
				{
					name: "近90天",
					code: "90",
				},
				{
					name: "近180天",
					code: "180",
				},
				{
					name: "近一年",
					code: "365",
				},
			],
			investmentList: [{
				value: [100000, ''],
				name: '100000㎡以上'
			},
			{
				value: [50000, ''],
				name: '50000㎡以上'
			}, {
				value: [10000, ''],
				name: '10000㎡以上'
			}, {
				value: [5000, ''],
				name: '5000㎡以上'
			}, {
				value: ['', 5000],
				name: '5000㎡以下'
			}, {
				value: [-1, ''],
				name: '其他'
			}
			],
			isInvestmentAll: true,
			landUseList: [{
				id: '20001',
				title: '商服用地',
				children: [
					// {
					// 	id: '30008',
					// 	text: '全选'
					// },
					{
						id: '30001',
						text: '零售商业用地'
					},
					{
						id: '30002',
						text: '批发市场用地'
					},
					{
						id: '30003',
						text: '餐饮用地'
					},
					{
						id: '30004',
						text: '旅馆用地'
					},
					{
						id: '30005',
						text: '商务金融用地'
					},
					{
						id: '30006',
						text: '娱乐用地'
					},
					{
						id: '30007',
						text: '其他商服用地'
					}
				]
			},
			{
				id: '20002',
				title: '工矿仓库用地',
				children: [
					// {
					// 	id: '30013',
					// 	text: '全选'
					// },
					{
						id: '30009',
						text: '工业用地'
					},
					{
						id: '30010',
						text: '采矿用地'
					},
					{
						id: '30011',
						text: '盐田'
					},
					{
						id: '30012',
						text: '仓储用地'
					},

				]
			},
			{
				id: '20003',
				title: '公共管理与公共服务用地',
				children: [
					// {
					// 	id: '30024',
					// 	text: '全选'
					// },
					{
						id: '30014',
						text: '机关团体用地'
					},
					{
						id: '30015',
						text: '新闻出版用地'
					},
					{
						id: '30016',
						text: '教育用地'
					},
					{
						id: '30017',
						text: '科研用地'
					},
					{
						id: '30018',
						text: '医疗卫生用地'
					},
					{
						id: '30019',
						text: '社会福利用地'
					},
					{
						id: '30020',
						text: '体育用地'
					},
					{
						id: '30021',
						text: '文化设施用地'
					},
					{
						id: '30022',
						text: '公用设施用地'
					},
					{
						id: '30023',
						text: '公园与绿地'
					},
				]
			},
			{
				id: '20004',
				title: '住宅用地',
				children: [
					// {
					// 	id: '30034',
					// 	text: '全选'
					// },
					{
						id: '30025',
						text: '城镇住宅用地'
					},
					{
						id: '30026',
						text: '农村宅基地'
					},
					{
						id: '30027',
						text: '城镇住宅-普通商品住房用地'
					},
					{
						id: '30028',
						text: '城镇住宅-经济适用住房用地'
					},
					{
						id: '30029',
						text: '城镇住宅-公共租赁住房用地'
					},
					{
						id: '30030',
						text: '城镇住宅-租赁型商品住房用地'
					},
					{
						id: '30031',
						text: '城镇住宅-共有产权住房用地'
					},
					{
						id: '30032',
						text: '城镇住宅-用于安置的商品房用地'
					},
					{
						id: '30033',
						text: '保障性租赁住房'
					}
				]
			},
			{
				id: '20005',
				title: '特殊用地',
				children: [
					// 	{
					// 		id: '30041',
					// 		text: '全选'
					// 	},
					{
						id: '30035',
						text: '军事设施用地'
					},
					{
						id: '30036',
						text: '使领馆用地'
					},
					{
						id: '30037',
						text: '监教场所用地'
					},
					{
						id: '30038',
						text: '宗教用地'
					},
					{
						id: '30039',
						text: '殡葬用地'
					},
					{
						id: '30040',
						text: '风景名胜设施用地'
					},
				]
			},
			{
				id: '20006',
				title: '交通运输用地',
				children: [
					// {
					// 	id: '30051',
					// 	text: '全选'
					// },
					{
						id: '30042',
						text: '铁路用地'
					},
					{
						id: '30043',
						text: '轨道交通用地'
					},
					{
						id: '30044',
						text: '公路用地'
					},
					{
						id: '3045',
						text: '城镇村道路用地'
					},
					{
						id: '30046',
						text: '交通服务场站用地'
					},
					{
						id: '30047',
						text: '农村道路'
					},
					{
						id: '30048',
						text: '机场用地'
					},
					{
						id: '30049',
						text: '港口码头用地'
					},
					{
						id: '30050',
						text: '管道运输用地'
					}
				]
			},
			{
				id: '20007',
				title: '水域及水利设施用地',
				children: [
					// {
					// 	id: '30062',
					// 	text: '全选'
					// },
					{
						id: '30052',
						text: '河流水面'
					},
					{
						id: '30053',
						text: '湖泊水面'
					},
					{
						id: '30054',
						text: '水库水面'
					},
					{
						id: '30055',
						text: '坑塘水面'
					},
					{
						id: '30056',
						text: '沿海滩涂'
					},
					{
						id: '30057',
						text: '内陆滩涂'
					},
					{
						id: '30058',
						text: '沟渠'
					},
					{
						id: '30059',
						text: '沼泽地'
					},
					{
						id: '30060',
						text: '水工建筑用地'
					},
					{
						id: '30061',
						text: '冰川及永久积雪'
					},
				]
			},
			{
				id: '20008',
				title: '其他用地',
				children: [
					// {
					// 	id: '30070',
					// 	text: '全选'
					// },
					{
						id: '30063',
						text: '空闲地'
					},
					{
						id: '30064',
						text: '设施农用地'
					},
					{
						id: '30065',
						text: '田坎'
					},
					{
						id: '30066',
						text: '盐碱地'
					},
					{
						id: '30067',
						text: '沙地'
					},
					{
						id: '30068',
						text: '裸土地'
					},
					{
						id: '30069',
						text: '裸岩石砾地'
					}
				]
			}
			],
			openMore: false,
			typeList: [{
				id: '30012',
				text: '划拨'
			},
			{
				id: '30013',
				text: '招拍挂出让'
			},
			{
				id: '30014',
				text: '招标出让'
			},
			{
				id: '30015',
				text: '拍卖出让'
			},
			{
				id: '30016',
				text: '挂牌出让'
			},
			{
				id: '30017',
				text: '协议出让'
			},
			{
				id: '30018',
				text: '租赁'
			},
			{
				id: '30019',
				text: '授权经营'
			},
			{
				id: '30020',
				text: '作价出资或入股'
			}],
			timeList: [{
				id: '30001',
				text: '3个月以内',
				count: 3,
				isAfter: false
			},
			{
				id: '30002',
				text: '6个月以内',
				count: 6,
				isAfter: false
			},
			{
				id: '30003',
				text: '1年以内',
				count: 12,
				isAfter: false
			},
			{
				id: '30004',
				text: '1年以后',
				count: 12,
				isAfter: true
			},
			{
				id: '30005',
				text: '2年以后',
				count: 24,
				isAfter: true
			}],
			priceList: [{
				id: '30006',
				text: '5亿元以上',
				transactionAmountStart: 50000,
				transactionAmountEnd: '',
				isOther: false
			},
			{
				id: '30007',
				text: '1亿元以上',
				transactionAmountStart: 100000,
				transactionAmountEnd: '',
				isOther: false
			},
			{
				id: '30008',
				text: '1000万元以上',
				transactionAmountStart: 1000,
				transactionAmountEnd: '',
				isOther: false
			},
			{
				id: '30009',
				text: '100万元以上',
				transactionAmountStart: 100,
				transactionAmountEnd: '',
				isOther: false
			},
			{
				id: '30010',
				text: '100万元以下',
				transactionAmountStart: '',
				transactionAmountEnd: 100,
				isOther: false
			},
			{
				id: '30011',
				text: '其他',
				transactionAmountStart: '-1',
				transactionAmountEnd: '',
				isOther: true
			}],
			childCodeList: [],
			selectedProData: [], // 已选的省级数据
			selectedCityData: [], // 已选地级市数据
			selectedYearData: [], // 已选地年份数据
			selectedTypeData: [],
			selecteTimeData: [],
			selectedPriceData: [],
			selectedStageData: [],
			selectedStageItem: {},
			selectedStage2Data: [],
			startYear: "",
			endYear: "",
			pageReport: [],
			conPO: {
				platform: 1,
				investmentIndex: '',
				// landAreaStart: '',
				// landAreaEnd: '',
				// customlandAreaStart: '',
				// customlandAreaEnd: '',
				// transactionAmountStart:'',
				// transactionAmountEnd:'',
				// search: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			total: 0,
			pageBidding: [],
			paginationKey: "",
			text: "",
			minDate: "",
			maxDate: "",
		};
	},
	computed: {
		startTime() {
			return {
				disabledDate: time => {
					let disableTime;
					if (this.endYear) {
						disableTime = time.getTime() > new Date(this.endYear).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						console.log("this.maxDate", this.maxDate);
						disableTime = time > this.maxDate || time < this.minDate;
					}

					return disableTime;
				},
			};
		},
		endTime() {
			return {
				disabledDate: time => {
					if (this.startYear) {
						return time.getTime() < new Date(this.startYear).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
		...mapGetters(["regionList"]),
	},
	filters: {
		moneyFormat(money) {
			if (money) {
				return (money / 10000) + '万';
			}
		},
	},
	mounted() {
		this.getTimeSelector();
		this.getTenderInfo();
	},
	methods: {
		getTimeSelector() {
			this.$api.industry.getTimeSelector().then(res => {
				this.minDate = new Date(res.data.minDateTime + " 00:00:00");
				this.maxDate = new Date(res.data.maxDateTime + ` 23:59:59`);
			});
		},
		// 拟建项目
		selectState(item, type) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (type == 1) {
					this.selectedStageItem = item;
					if (this.selectedStageData.indexOf(item) != -1) {
						this.selectedStageData.splice(this.selectedStageData.indexOf(item), 1)
						item.children.map(ite => {
							if (this.selectedStage2Data.indexOf(ite.text) != -1) {
								this.selectedStage2Data.splice(this.selectedStage2Data.indexOf(ite.text), 1)
							}
						})
					} else {
						this.selectedStageData.push(item);
						this.childCodeList = item.children
						this.childCodeList.map(ite => {
							if (this.selectedStage2Data.indexOf(ite.text) == -1) {
								this.selectedStage2Data.push(ite.text)
							}
						})
					}
				} else {
					if (item.text == '全选') {

						if (this.selectedStage2Data.indexOf(item.text) == -1) {//全选
							this.childCodeList.map(ite => {
								if (this.selectedStage2Data.indexOf(ite.text) == -1) {
									this.selectedStage2Data.push(ite.text)
								}
							})
						} else {//取消全选
							this.childCodeList.map(ite => {
								if (this.selectedStage2Data.indexOf(ite.text) != -1) {
									this.selectedStage2Data.splice(this.selectedStage2Data.indexOf(ite.text), 1)
								}
							})
						}
					} else {
						if (this.selectedStage2Data.indexOf(item.text) == -1) {
							this.selectedStage2Data.push(item.text)
						} else {
							// 取消选择 
							this.selectedStage2Data = this.selectedStage2Data.filter(ite => item.text != ite)
						}
					}

				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 数组去重
		removeDuplicates(arr1, arr2) {
			const uniqueArr = arr2.filter((item, index) => !arr1.includes(item));
			return uniqueArr;
		},
		// 点击搜索
		handleQuery() {
			if (this.$session.getUsers()) {
				this.$refs.pagination.page = 1;
				this.conPO.pageNum = 1;
				this.conPO.pageSize = 10;
				this.getTenderInfo();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},

		// 将时间戳转换成日期格式：
		timestampToTime(timestamp) {
			// 时间戳为10位需*1000，时间戳为13位不需乘1000
			var date = new Date(timestamp * 1000);
			var Y = date.getFullYear() + "-";
			var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
			var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			var h = date.getHours() + ":";
			var m = date.getMinutes() + ":";
			var s = date.getSeconds();
			return Y + M + D;
		},
		getMonthAfterThreeMonths(currentMonth, n) {
				// 如果当前月份小于10，前面补0
				currentMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
				// 获取当前年份
				var currentYear = new Date().getFullYear();
				// 将当前月份加3，并对12进行模运算以确保月份在1-12之间
				var threeMonthsLater = (Number(currentMonth) + n) % 12;
				// 如果三个月后的月份小于10，前面补0
				threeMonthsLater = threeMonthsLater < 10 ? '0' + threeMonthsLater : threeMonthsLater;
				// 获取三个月后的年份，如果月份加3后超过12，则年份需要增加1
				var threeMonthsYear = currentYear + Math.floor((Number(currentMonth) + n) / 12);
				const today = new Date();
				return threeMonthsYear + '-' + threeMonthsLater + '-' + today.getDate();
		},
		// 获取列表
		getTenderInfo() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let { conPO, proSelect, citySelect, yearSelect, startYear, endYear, selectedStage2Data } = this;
			let provinces = [];
			proSelect.map(item => {
				provinces.push(item.code);
			});
			conPO.provinceCodes = provinces.toString();

			let citys = [];
			citySelect.map(item => {
				citys.push(item.code);
			});
			conPO.cityCodes = citys.toString();
			conPO.dayCount = yearSelect.toString();
			conPO.signStartDate = startYear;
			conPO.signEndDate = endYear;
			// 用途筛选数据
			let projectStates = [];
			selectedStage2Data.map(item => {
				if (item != '全部') {
					projectStates.push(item);
				}
			});
			conPO.landUse = ''
			if (projectStates.length) {
				conPO.landUse = projectStates.toString();
			}else{
				delete conPO.landUse 
			}
			conPO.commencementStartDate = ''
			conPO.commencementEndDate = ''
			if (this.selecteTimeData && this.selecteTimeData.length) {
				// 获取当前月份
				var currentMonth = new Date().getMonth() + 1;
						// 获取三个月后的月份和年份
				var threeMonthsAfter = this.getMonthAfterThreeMonths(currentMonth, this.selecteTimeData[0]['count']); 
				if (this.selecteTimeData[0]['isAfter']) {
					conPO.commencementStartDate = threeMonthsAfter
				} else {
					conPO.commencementEndDate = threeMonthsAfter
				}
			}

			conPO.transactionAmountStart = ''
			conPO.transactionAmountEnd = ''
			if (this.selectedPriceData && this.selectedPriceData.length) {
				conPO.transactionAmountStart = this.selectedPriceData[0] && this.selectedPriceData[0]['isOther'] ? (-1) : (this.selectedPriceData[0].transactionAmountStart* 10000 || '')
				conPO.transactionAmountEnd = this.selectedPriceData[0].transactionAmountEnd* 10000 || ''
			}
			this.selectedTypeData.map(item => { })
			conPO.supplyMode = this.selectedTypeData.map(item => { return item.text }).join(',')

			conPO.landAreaEnd=''
			conPO.landAreaStart=''
			if (conPO.investmentIndex || conPO.investmentIndex === 0) {
				conPO.landAreaEnd = this.investmentList[conPO.investmentIndex].value[1]
				conPO.landAreaStart = this.investmentList[conPO.investmentIndex].value[0]
			}
			if (conPO.customlandAreaEnd || conPO.customlandAreaStart) {
				conPO.landAreaEnd = conPO.customlandAreaEnd
				conPO.landAreaStart = conPO.customlandAreaStart
			}
			let func =this.$api.industry.getLandTransactionPage(conPO)
			if(this.subscribeId){
				conPO.subscribeId=this.subscribeId
				func=this.$api.industry.getSubscribeLandTransactionPage(conPO)
			} 
			func.then(res => {
					loading.close();
					this.total = res.total;
					let contentsHighLightReg =
						/[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]+|<em>[^<]*<\/em>/g;
					try {
						res.rows.forEach(item => {
							// item.publishedDate = item.publishedDate.slice(0, 10);
							if (item.projectNameHighLight) {
								item.projectNameHighLight = item.projectNameHighLight
									.replace(/<em/g,
										'<em style="color:#d8001b;font-style: normal;"')
							}
							if (item.contentsHighLight) {
								item.contentsHighLight = item.contentsHighLight.match(
									contentsHighLightReg).join("");
								item.contentsHighLight = item.contentsHighLight
									.replace(/<em/g,
										'<em style="color:#d8001b;font-style:normal;"')
							}
						});
					} catch (e) {
						console.log(e)
					}
					this.pageBidding = res.rows;
				})
				.catch(msg => {
					loading.close();
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 请求市区城
		getCity(item) {
			let obj = {
				parentRegion: item.regionId,
				isLazyLoad: 10011001,
				state: 10021001,
				hasChildren: true
			}
			this.$api.system.regionList(obj).then(res => {
				if (res.data.length > 0) {
					res.data.map(item => {
						item.name = item.regionFullname
						item.code = item.unionCode
					})
					let data = res.data;
					this.cityData = [...data];
				}
			});
		},
		 
		// 展开收起省份
		foldProvinces() {
			this.provincesStatus = !this.provincesStatus;
		},
		// 展开收起地级市
		foldCity() {
			this.cityStatus = !this.cityStatus;
		},
		// 单选省份
		selectPro(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let { proSelect, cityData, selectedProData, citySelect, selectedCityData } = this;
				let valueIndex = proSelect.indexOf(item);
				if (valueIndex == -1) {
					this.proSelect.push(item);
					this.selectedProData.push(item)
					if (this.proSelect.length == 0 && this.citySelect.length == 0) {
						this.isProAll = true;
					} else {
						this.isProAll = false;
					}
					// 判断市级数据中是否已经存在当前省的市级数据
					if (citySelect.some(key => key.code.slice(0, 2) == item.code.slice(0, 2))) {
						// 处理市级数据（不需要当前省级的市级数据）
						let newCitySelect = [];
						citySelect.forEach(key => {
							if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
								newCitySelect.push(key);
							}
						});
						this.citySelect = newCitySelect;
						// 处理已选条件的市级数据（不需要当前省级的市级数据）
						let newSelectedCityData = [];
						selectedCityData.forEach(key => {
							if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
								newSelectedCityData.push(key);
							}
						});
						this.selectedCityData = newSelectedCityData; 
					}
					this.handleQuery();
					// 选择
					this.getCity(item);
				} else {
					// 取消选择
					proSelect.splice(valueIndex, 1);
					// 删除对应的市级数据
					let cityResult = [];
					cityData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							cityResult.push(key);
						}
					});
					this.cityData = cityResult;
					// （删除已选的省级数据）比较 item和selectedProData取出不同的值
					let itemArr = [item];
					let selectedCodes = [];
					itemArr.forEach(({ name }) => {
						selectedCodes.push(name);
					});
					let selectedResult = selectedProData.filter(({ name }) => !selectedCodes.includes(name));
					this.selectedProData = selectedResult;
					this.handleQuery();
				}
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 全选省份
		proAll() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					return;
				}
				this.isProAll = !this.isProAll;
				if (this.isProAll) {
					this.selectedProData = [];
					this.proSelect = [];
					this.cityData = [];
					this.selectedCityData = [];
					this.citySelect = [];
				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 单选地级市
		selectCity(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let { citySelect, selectedCityData, proSelect, selectedProData } = this;
				let valueIndex = citySelect.indexOf(item);
				if (valueIndex == -1) {
					// 选择
					citySelect.push(item);
					this.selectedCityData = [...selectedCityData, ...[item]];
					// 处理省级数据（不需要当前市级的省级数据）
					let newCitySelect = [];
					proSelect.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newCitySelect.push(key);
						}
					});
					this.proSelect = newCitySelect;
					// 处理已选条件的省级数据（不需要当前市级的省级数据）
					let newSelectedProData = [];
					selectedProData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newSelectedProData.push(key);
						}
					});
					this.selectedProData = newSelectedProData;
					if (this.proSelect.length == 0) {
						this.isProAll = false;
					}
					this.handleQuery();
				} else {
					// 取消选择
					citySelect.splice(valueIndex, 1);
					// 比较 item和selectedCityData取出不同的值
					let itemArr = [item];
					let selectedCodes = [];
					itemArr.forEach(({ code }) => {
						selectedCodes.push(code);
					});
					let selectedResult = selectedCityData.filter(({ code }) => !selectedCodes.includes(code));

					if (selectedResult.length == 0) {
						// 无选中的市时
						this.isProAll = true;
					}
					this.selectedCityData = selectedResult;
					this.handleQuery();
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 全选年份
		yearAll() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (this.yearSelect.length == 0 && this.startYear == "" && this.endYear == "") {
					return;
				}
				this.isYearAll = !this.isYearAll;
				if (this.isYearAll) {
					this.startYear = "";
					this.endYear = "";
					this.selectedYearData = [];
					this.yearSelect = [];
				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 单选年份
		selectYear(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				let { yearSelect } = this;
				let valueIndex = yearSelect.indexOf(item.code);
				if (valueIndex == -1) {
					// 选择
					this.yearSelect = [item.code];
					this.selectedYearData = [item];
					this.startYear = "";
					this.endYear = "";
					this.handleQuery();
				} else {
					// 取消选择
					this.yearSelect = [];
					this.selectedYearData = [];
					this.handleQuery();
				}
				if (this.yearSelect.length == 0) {
					this.isYearAll = true;
				} else {
					this.isYearAll = false;
				}
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		investmentAll() {
			this.isInvestmentAll = true
			this.conPO.investmentIndex = ''
			this.conPO.landAreaStart = ''
			this.conPO.landAreaEnd = ''
			this.conPO.customlandAreaStart = ''
			this.conPO.customlandAreaEnd = ''
			this.handleQuery();
		},
		selectInvestment(e, index) {
			if (this.conPO.investmentIndex === index) {
				this.isInvestmentAll = true
				this.conPO.investmentIndex = ''
				this.conPO.landAreaStart = ''
				this.conPO.landAreaEnd = ''
			} else {
				this.isInvestmentAll = false
				this.conPO.investmentIndex = index
				this.conPO.landAreaStart = e.value[0]
				this.conPO.landAreaEnd = e.value[1]
			}
			this.handleQuery();
		},
		changeCustomInvestment(e, index) {
			this.handleQuery();
		},
		// 改变时间
		changeYear() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.isYearAll = false;
				this.selectedYearData = [];
				this.yearSelect = [];
				if ((this.startYear == "" || this.startYear == null) && (this.endYear == "" || this.endYear == null)) {
					this.isYearAll = true;
				}
				this.handleQuery();
			} else {
				this.startYear = null;
				this.endYear = null;
				this.$refs.loginModal.visible = true;
			}
		},
		// 删除单条所选内容
		delSelectData(item, index, type) {

			if (type == 1) {
				// 删除省
				let { proSelect, citySelect, cityData } = this;
				this.selectedProData.splice(index, 1);
				// 删除对应的市级数据
				let cityResult = [];
				cityData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						cityResult.push(key);
					}
				});
				this.cityData = cityResult;
				let newData = proSelect.filter(key => ![item.name].includes(key.name));
				// if (newData.length == 0 && this.selectedCityData.length == 0) {
				//   // 无选中的省，市
				//   this.isProAll = true;
				//   this.selectedProData = [];
				//   this.proSelect = [];
				//   this.cityData = [];
				//   this.selectedCityData = [];
				//   this.citySelect = [];
				// }
				this.proSelect = newData;
				if (proSelect.length == 1 && citySelect.length == 0) {
					this.cityData = [];
				}
				this.getTenderInfo();
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else if (type == 2) {
				// 删除市
				let { citySelect, selectedProData } = this;
				this.selectedCityData.splice(index, 1);
				let newData = citySelect.filter(key => ![item.name].includes(key.name));
				if (newData.length == 0) {
					this.selectedCityData = [];
					this.citySelect = [];
				}
				if (newData.length == 0 && this.proSelect.length == 0) {
					this.isProAll = true;
				}
				this.citySelect = newData;
				if (citySelect.length == 1 && selectedProData.length == 0) {
					this.cityData = [];
				}
				this.handleQuery();
			} else if (type == 3) {
				// 删除年份
				this.isYearAll = true;
				this.selectedYearData = [];
				this.yearSelect = [];
				this.handleQuery();
			} else if (type == 4) {
				this.selectedTypeData.splice(index, 1);
				this.handleQuery();
			} else if (type == 5) {
				// this.projectState = false;
				this.selectedStage2Data.splice(index, 1);
				this.handleQuery();
			} else if (type == 6) {
				this.selecteTimeData=[];
				this.handleQuery();
			} else if (type == 7) {
				this.selectedPriceData.splice(index, 1);
				this.handleQuery();
			} else if (type == 8) {
				this.isInvestmentAll = true
				this.conPO.investmentIndex = ''
				this.handleQuery();
			}
		},
		// 点击类型
		selectType(item, type) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				if (type == 1) { 
					let valueIndex = this.selecteTimeData.indexOf(item);
					if (valueIndex == -1) {
						// 选择
						this.selecteTimeData=[item]
					} else {
						// 取消选择
						this.selecteTimeData =[]
					}
				} else if (type == 2) {
					let { selectedPriceData } = this;
					let valueIndex = selectedPriceData.indexOf(item);
					if (valueIndex == -1) {
						// 选择 
						this.selectedPriceData = [item];
					} else {
						// 取消选择 
						this.selectedPriceData = [];
					}
				} else if (type == 3) {
					let { selectedTypeData } = this;
					let valueIndex = selectedTypeData.indexOf(item);
					if (valueIndex == -1) {
						// 选择
						selectedTypeData.push(item);
						this.selectedTypeData = selectedTypeData;
					} else {
						// 取消选择
						selectedTypeData.splice(valueIndex, 1);
						this.selectedTypeData = selectedTypeData;
					}
				}
				this.handleQuery();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 清空筛选条件
		emptySelect() {
			this.cityData = [];
			this.proSelect = [];
			this.isProAll = true;
			this.citySelect = [];
			this.isYearAll = true;
			this.yearSelect = [];
			this.selectedProData = [];
			this.selectedCityData = [];
			this.selectedYearData = [];
			this.startYear = "";
			this.endYear = "";
			this.selectedTypeData = [];
			this.selectedStageData = [];
			this.projectState = false;
			this.conPO.investmentIndex = ''
			this.selectedStageItem = {}
			this.selectedStageData = []
			this.selectedStage2Data = []
			this.selecteTimeData = []
			this.selectedPriceData = []
			this.selectedTypeData = []
			this.handleQuery();
		},
		// 点击列表查看详情
		toDetail(item) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.getTenderInfoDetail(item.id);
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		getTenderInfoDetail(id) {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			let params = {
				moduleCode: 1006,
				childModuleCode: 10061004,
				type: 2,
			};
			this.$api.industry
				.getMemberPermission(params)
				.then(res => {
					console.log("res", res);
					if (res.data != 0) {
						const routeUrl = this.$router.resolve({
							path: `/project/land-detail?id=${id}`,
						});
						window.open(routeUrl.href, "_blank");
					} else {
						this.text = "您今日免费次数已使用完，请开通权限后继续使用";
						this.$refs.toPayOrMember.openDialog();
					}
				})
				// .catch(msg => {
				// 	console.log("msg", msg);
				// 	if (msg.code == "10001111") {
				// 		this.$refs.toPayOrMember.openDialog();
				// 		this.text = msg?.msg;
				// 	} else {
				// 		this.$message.error(msg?.msg);
				// 	}
				// })
				.finally(() => {
					loading.close();
				});
		},
		// 分页方法
		paginChange(page, pageSize) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.conPO.pageNum = page;
				this.conPO.pageSize = pageSize;
				this.getTenderInfo();
			} else {
				this.paginationKey = new Date().getTime();
				this.$refs.loginModal.visible = true;
			}
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
